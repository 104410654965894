import React, { Component } from 'react';
import moment from 'moment';
import {
  caretBackCircleOutline as caretLeft,
  caretForwardCircleOutline as caretRight,
  closeOutline as close,
} from 'ionicons/icons';

import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonTitle,
  IonLabel,
  IonIcon,
  IonChip,
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonFooter,
  IonTextarea,
  withIonLifeCycle,
} from '@ionic/react';

import ReservationsService from '../services/reservations';
import ProfileService from '../services/profile';

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.serviceRef = React.createRef('');
    this.profileRef = React.createRef('');
    this.selectedTimeRef = React.createRef('');
    this.pickedDateRef = React.createRef('');
    this.selectedStaffIdRef = React.createRef('');
    this.noteRef = React.createRef('');

    this.state = {
      availableDate: null,
      pickedDateTime: null,
      isModalOpen: false,
      isActive: '',
      calendarDayProgression: [],
    };
  }

  async ionViewWillEnter() {
    if (!!this.props.loader) this.props.loader.current.show();
    try {
      const {
        data: { service },
        data: { availableDate },
      } = await ReservationsService.current(this.props.serviceId);

      const {
        data: { data: profile },
      } = await ProfileService.me();

      await this.setState({ availableDate });
      this.serviceRef.current = service;
      this.profileRef.current = profile;
      this.setCalendarlimitsDate();
    } catch (e) {
      console.error(e);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  async ionViewWillLeave() {
    this.setState({ isModalOpen: false });
    this.setState({ pickedDateTime: null });
    this.setState({ availableDate: null });
    this.setState({ isActive: '' });
    this.setState({ calendarDayProgression: [] });

    this.serviceRef.current = '';
    this.profileRef.current = '';
    this.selectedTimeRef.current = '';
    this.pickedDateRef.current = '';
    this.selectedStaffIdRef.current = '';
    this.noteRef.current = '';
  }

  async fetchDateTime(fullDate) {
    if (!!this.props.loader) this.props.loader.current.show();
    try {
      const {
        data: { availableSlots },
        data: { pickedDate },
      } = await ReservationsService.pickDate(this.serviceRef.current.id, fullDate);

      await this.setState({ pickedDateTime: availableSlots });
      this.pickedDateRef.current = pickedDate;
    } catch (e) {
      console.error(e);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  async submitHandler(that) {
    let endAppointment = moment(`${that.pickedDateRef.current} ${that.selectedTimeRef.current}`)
      .add(that.serviceRef.current.duration, 'm')
      .subtract(1, 's')
      .format('HH:mm:ss');

    try {
      await ReservationsService.saveReservation({
        user_id: that.profileRef.current.id,
        service_id: that.serviceRef.current.id,
        staff_id: that.selectedStaffIdRef.current,
        day: that.pickedDateRef.current,
        start_appointment: that.selectedTimeRef.current,
        end_appointment: endAppointment,
        note: that.noteRef.current,
      });

      await that.props.toast.current.add({ message: `Prenotaziona effettuata correttamente.` });
      await this.fetchDateTime(that.pickedDateRef.current);
      that.setState({ isModalOpen: false });
      that.props.history.push('/app/reservations');
    } catch (e) {
      console.error(e);
      that.props.toast.current.add({
        message: `Problema nella conferma della prenotazione.`,
        color: 'danger',
      });

      delete that.props.toast.current.onDidDismiss;
    }
  }

  reset() {
    this.pickedDateRef.current = '';
    this.setState({ pickedDateTime: null });
    this.setState({ isActive: '' });
  }

  setCalendarlimitsDate() {
    const arr = [];
    let _arr = [];
    let startOfCalendar = this.props.momentDate.clone().startOf('month');
    let endOfCalendar = this.props.momentDate.clone().endOf('month');
    let startOfMonthNum = Number(startOfCalendar.format('d')) - 1;
    let endOfMonthNum = Number(endOfCalendar.format('d')) - 1;

    startOfCalendar = this.setCalendarLimits(startOfCalendar, startOfMonthNum, true);
    endOfCalendar = this.setCalendarLimits(endOfCalendar, endOfMonthNum, false);

    while (startOfCalendar.format('YYYY-MM-DD') !== endOfCalendar.format('YYYY-MM-DD')) {
      _arr.push(startOfCalendar.clone());
      if (Number(startOfCalendar.format('d')) === 0) {
        arr.push(_arr);
        _arr = [];
      }

      startOfCalendar = startOfCalendar.add(1, 'd');
    }

    this.setState({ calendarDayProgression: arr });
  }

  setCalendarLimits(obj, num, bool) {
    if (num === -1) num = 6;
    if (bool) {
      if (num !== 0) {
        obj = obj.subtract(num, 'd');
      }
    } else {
      if (num !== 6) {
        obj = obj.add(7 - num, 'd');
      } else if (num === 6) {
        obj = obj.add(1, 'd');
      }
    }

    return obj;
  }

  render() {
    const { availableDate, isActive, pickedDateTime, isModalOpen } = this.state;

    return (
      <>
        <IonGrid>
          <IonRow className="ion-margin-vertical">
            <IonCol size="1">
              <IonIcon
                icon={caretLeft}
                style={{ height: '30px', width: '30px' }}
                onClick={() => {
                  this.props.prevMonth();
                  this.setCalendarlimitsDate();
                  this.reset();
                }}
              />
            </IonCol>
            <IonCol>
              <IonTitle className="ion-text-uppercase ion-text-center">{this.props.momentDate.format('MMMM')}</IonTitle>
            </IonCol>
            <IonCol className="ion-padding-end" size="1">
              <IonIcon
                className="ion-float-end"
                icon={caretRight}
                style={{ height: '30px', width: '30px' }}
                onClick={() => {
                  this.props.nextMonth();
                  this.setCalendarlimitsDate();
                  this.reset();
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonItem className="ion-no-margin ion-no-padding">
          <table style={{ width: '100%', margin: 'auto auto 10px auto' }}>
            <thead>
              <tr>
                <th>Lun</th>
                <th>Mar</th>
                <th>Mer</th>
                <th>Gio</th>
                <th>Ven</th>
                <th>Sab</th>
                <th>Dom</th>
              </tr>
            </thead>

            <tbody>
              {this.state.calendarDayProgression.map((weekDate) => {
                return (
                  <tr key={weekDate[0].format('MM-DD')}>
                    {weekDate.map((dayDate) => {
                      const day = dayDate.format('DD');
                      const fullDate = dayDate.format('YYYY-MM-DD');
                      let isDisabled = true;
                      let color = 'light';

                      if (availableDate && availableDate.indexOf(fullDate) !== -1) {
                        isDisabled = false;
                        color = isActive === fullDate ? 'middle' : 'dark';
                      }

                      return (
                        <td key={fullDate}>
                          <IonChip
                            id={fullDate}
                            color={color}
                            outline={true}
                            disabled={isDisabled}
                            onClick={() => {
                              if (isDisabled) return;
                              this.setState({ isActive: fullDate });
                              this.fetchDateTime(fullDate);
                            }}
                          >
                            <IonLabel>{day}</IonLabel>
                          </IonChip>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </IonItem>

        {pickedDateTime && (
          <IonItem className="ion-margin-vertical ion-padding-bottom">
            <IonCol className="ion-align-self-start">
              {pickedDateTime.map((timeInterval, index) => {
                return (
                  <IonChip
                    key={index}
                    color="success"
                    outline={true}
                    onClick={() => {
                      this.setState({ isModalOpen: true });
                      this.selectedTimeRef.current = timeInterval.time;
                      this.selectedStaffIdRef.current = timeInterval.staffId;
                    }}
                  >
                    <IonLabel>{timeInterval.time}</IonLabel>
                  </IonChip>
                );
              })}
            </IonCol>
          </IonItem>
        )}

        {isModalOpen && (
          <IonModal isOpen={isModalOpen}>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton
                    onClick={() => {
                      this.selectedTimeRef.current = '';
                      this.selectedStaffIdRef.current = '';
                      this.setState({ isModalOpen: false });
                      this.noteRef.current = '';
                    }}
                  >
                    <IonIcon icon={close} />
                  </IonButton>
                </IonButtons>
                <IonTitle>Riepilogo</IonTitle>
              </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
              <ion-item-group>
                <ion-item-divider>
                  <IonLabel className="ion-text-uppercase" color="dark">
                    <b>Dati Personali</b>
                  </IonLabel>
                </ion-item-divider>
                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Nome:</h4>
                    <p className="ion-text-capitalize">{this.profileRef.current.name}</p>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Email:</h4>
                    <p>{this.profileRef.current.email}</p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Telefono:</h4>
                    <p>{this.profileRef.current.phone_number}</p>
                  </IonLabel>
                </IonItem>
              </ion-item-group>

              <ion-item-group>
                <ion-item-divider>
                  <IonLabel className="ion-text-uppercase" color="dark">
                    <b>Prenotazione</b>
                  </IonLabel>
                </ion-item-divider>
                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Servizio:</h4>
                    <p>{this.serviceRef.current.name}</p>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Giorno:</h4>
                    <p>{moment(this.pickedDateRef.current).format('dddd | DD MMMM YYYY')}</p>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Ora:</h4>
                    <p>{this.selectedTimeRef.current}</p>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Prezzo:</h4>
                    <p>{this.serviceRef.current.price} €</p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Durata:</h4>
                    <p>{this.serviceRef.current.duration} min</p>
                  </IonLabel>
                </IonItem>
              </ion-item-group>

              <ion-item-group>
                <ion-item-divider>
                  <IonLabel className="ion-text-uppercase" color="dark">
                    <b>Note</b>
                  </IonLabel>
                </ion-item-divider>
                <IonItem lines="none">
                  <IonLabel>
                    <h4 className="ion-text-uppercase">Note al farmacista:</h4>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonTextarea
                    type="text"
                    name="note"
                    placeholder="Indica qui se hai richieste particolari da inviare al Farmacista"
                    value={this.noteRef.current}
                    onIonChange={(e) => (this.noteRef.current = e.detail.value)}
                  />
                </IonItem>
              </ion-item-group>
            </IonContent>

            <IonFooter>
              <IonToolbar>
                {this.profileRef.current ? (
                  <IonButton
                    className="ion-margin-horizontal"
                    expand="block"
                    color="success"
                    onClick={() => this.submitHandler(this)}
                  >
                    Conferma prenotazione
                  </IonButton>
                ) : (
                  <IonButton
                    expand="block"
                    color="light"
                    className="ion-margin-horizontal"
                    onClick={() => {
                      this.setState({ isModalOpen: false });
                      this.props.history.push(`/auth/login?redirect=/app/reservations`);
                    }}
                  >
                    Accedi per richiedere informazioni
                  </IonButton>
                )}
              </IonToolbar>
            </IonFooter>
          </IonModal>
        )}
      </>
    );
  }
}

export default withIonLifeCycle(Calendar);
