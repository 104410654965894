import React, { Component } from 'react';

import QueryString from "query-string";

import { withIonLifeCycle } from '@ionic/react';
import { IonContent } from '@ionic/react';
import { Link } from 'react-router-dom';
import { IonHeader, IonToolbar, IonSegment, IonSegmentButton, IonLabel, IonTitle } from '@ionic/react';

import AuthResource from '../resources/Auth';

import Login from '../components/Login';
import Signup from '../components/Signup';
import TrackingService from '../services/tracking';
import SystemResource from '../resources/System';
import OptionService from '../services/option';

class LoginPage extends Component {

  constructor(props) {
    super(props);

    const { segment } = QueryString.parse(this.props.location.search);

    this.state = {
      segment: !!segment ? segment : 'login',
      recovery_sent: false,
      shop_name:'',
      shop_legal:'',
    };

    this.onLogin = this.onLogin.bind(this);
    this.onRecovery = this.onRecovery.bind(this);
    this.onSignup = this.onSignup.bind(this);
  }

  async componentDidMount() {
    // let isLogged = await AuthResource.check();
    // if (!!isLogged) {
    //   this.props.history.replace('/app/user');
    // }
  }

  async ionViewWillEnter() {
    this.setState({ recovery_sent: false });
    if(!!this.props.loader && !!this.props.loader.current ){
      this.props.loader.current.dismiss();
    }
    let isLogged = await AuthResource.isLogged;
    if (!!isLogged) {
      this.props.history.replace('/app/user');
    }
    
    const { segment } = QueryString.parse(this.props.location.search);
    if (!!segment) {
      this.setState({ segment });
    }

    let [ 
      {value: {data: shop_name}}, 
      {value: {data: shop_legal}}, 
      {value: {data: shop_url}}, 
    ]  = await Promise.allSettled([
      OptionService.get('shop_name'),
      OptionService.get('shop_legal'),
      OptionService.get('shop_url'),
    ]);

    this.setState({shop_name: (!!shop_name?.value ? shop_name.value : '')}) ;
    this.setState({shop_legal: (!!shop_legal?.value ? shop_legal.value : '')}) ;
    this.setState({shop_url: (!!shop_url?.value ? shop_url.value : '')}) ;

  }

  async onLogin(error) {
    if (!!error) {
      this.props.toast.current.add({
        message: `Credenziali sbagliate`,
        color: 'danger',
      });
      console.error(error);
      return;
    }

    const { redirect } = QueryString.parse(this.props.location.search);
    if (!!redirect) {
      return await this.props.history.replace(redirect);
    }
    TrackingService.login();
    this.props.history.replace('/app/user');
  }

  async onRecovery(error) {
    if (!!error) {
      this.props.toast.current.add({
        message: `Credenziali sbagliate`,
        color: 'danger',
      });
      console.error(error);
      this.setState({ recovery_sent: false });
      return;
    }
    this.setState({ recovery_sent: true });
    TrackingService.recoveryBegin();
    this.props.toast.current.add({
      message: `Richiesta inviata correttamente.`,
    });

  }

  async onSignup(error) {
    if (!!error) {      
      console.error(error);
      return;
    }
    await AuthResource.check();

    const { redirect } = QueryString.parse(this.props.location.search);
    if (!!redirect) {
      return await this.props.history.replace(redirect);
    }
    TrackingService.signup();

    this.props.history.replace('/app/user');
  }

  async updateApp() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          window.location.reload();
        });
      });
    }
  }

  render() {
    const version = `${SystemResource.apiversion}-${process.env.REACT_APP_VERSION}`;

    return (
      <>
        <IonHeader mode="md">
          <IonToolbar>
            <IonTitle>Il mio profilo</IonTitle>
          </IonToolbar>

          <IonToolbar>
            <IonSegment 
              value={this.state.segment}
              onIonChange={e => (this.setState({ segment: e.detail.value, recovery_sent: false }))}
            >
              <IonSegmentButton value="login">
                <IonLabel>Accedi</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="signup">
                <IonLabel>Registrati</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar> 
        </IonHeader>

        <IonContent className="ion-no-padding">
            {!this.state.recovery_sent && (
              <>
                {this.state.segment === 'login' && (
                  <div className="ion-padding">
                    <Login {...this.props} 
                      onSubmit={this.onLogin} 
                      onRecovery={this.onRecovery}
                    />
                  </div>
                )}
              </>
            )}
            {this.state.segment === 'signup' && (
              <div className="ion-padding">
                <Signup {...this.props }  onSubmit={this.onSignup} />
              </div>
            )}
            {this.state.recovery_sent && (
              <div className="ion-padding">
                <h3>Mail inviata</h3>
                <h4>Segui le istruzioni</h4>
                <p>Controlla la tua casella di posta, ti abbiamo inviato una mail per proseguire con il cambio della tua password.</p>
                <br/><br/><br/>
                <a href='/auth/login' onClick={(e) => {
                    e.preventDefault();
                    this.setState({ state: 'login', recovery_sent: false });
                  }}
                >Torna al login</a>
              </div>
            )}
            <div className="ion-padding ">
              { this.state.shop_legal === '' 
              ?<>Informazioni legali obbligatorie non inserite!</>
              :<><small>{this.state.shop_url} {this.state.shop_name} <br/> è un servizio di: {this.state.shop_legal}<br/></small></>
              }
              <small><Link to="/app/privacy">informativa sulla privacy</Link> e <Link to="/app/tos">termini di utilizzo</Link><br/></small>
              <small onClick={this.updateApp}>Versione {version}</small><br/>
            </div>
          </IonContent>
      </>
    );
  }
}

export default withIonLifeCycle(LoginPage);
