import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton} from '@ionic/react';
import { IonList, IonItem, IonToggle, IonLabel } from '@ionic/react';
import { IonAlert } from '@ionic/react';

import NotificationResource from '../resources/Notification/Notification'

class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pushNotification: false,
      pushNotificationDisabled: false,
      pushNotificationAlert: false,
      smsNotification: false,
      emailNotification: false,

      version: undefined,
    };

    this.requestPermission = this.requestPermission.bind(this);
    this.handlePushNotification = this.handlePushNotification.bind(this);
    this.handlePushNotificationCheck = this.handlePushNotificationCheck.bind(this);

    this.handleEmailNotification = this.handleEmailNotification.bind(this);
    this.handleSmsNotification = this.handleSmsNotification.bind(this);
  }

  async ionViewWillEnter() {

    await this.setState({
      emailNotification: await NotificationResource.status('email')
    });
    await this.setState({
      smsNotification: await NotificationResource.status('sms')
    });
    switch ( Notification.permission ) {      
      case 'denied':
        await this.setState({
          pushNotification: false,
          pushNotificationDisabled: true,
        });
        break;
          
      case 'granted':
        await this.setState({
          pushNotification: await NotificationResource.push.status(),
          pushNotificationDisabled: false,
        });
        break;

      default:
        if (!!!localStorage.getItem('pushNotification')) {
          await this.requestPermission();
        }
        localStorage.setItem('pushNotification', new Date());
        return;
    }

  }

  async requestPermission() {
    switch ( Notification.permission ) {      
      case 'denied':
        await this.setState({
          pushNotification: false,
          pushNotificationDisabled: true,
        });
        break;
          
      case 'granted':
        await this.handlePushNotification(true);
        break;

      default:
        await this.setState({ pushNotificationAlert: true });
    }
  }

  async handlePushNotification(state) {
    try {

      !!state 
        ? await NotificationResource.push.active()
        : await NotificationResource.push.disable()

      await this.setState({
        pushNotification: await NotificationResource.status('push'),
        pushNotificationDisabled: Notification.permission === 'denied' ? true : false,
      });

      this.props.toast.current.add({
        message: `Notifiche push ${!!state ? 'attivate' : 'disabilitate' }.`
      });
      
    } catch (error) {
      this.handlePushNotificationError(error);
    }
  }

  async handlePushNotificationError(error) {
    try {
      switch (error.code) {
        case 'messaging/permission-blocked':  
          this.props.toast.current.add({
              message: `Non è stata garantito il permesso all'applicazione per ricevere notifiche push.`,
              color: 'danger',
              position: 'bottom'
            });     
          break;
      
        default:
          this.props.toast.current.add({
            message: `Problema generico nella attivazione le notifiche push.`,
            color: 'danger',
            position: 'bottom'
          });
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handlePushNotificationCheck(e) {
    if (Notification.permission === 'default') {
      return await this.requestPermission();
    }

    this.handlePushNotification(e.detail.checked);
  }


  async handleEmailNotification(e) {
    try {

      !!e.detail.checked
        ? await NotificationResource.active('email')
        : await NotificationResource.disable('email')

      await this.setState({
        // emailNotification: e.detail.checked
        emailNotification: await NotificationResource.status('email')
      });

      this.props.toast.current.add({
        message: `Notifiche email ${!!e.detail.checked ? 'attivate' : 'disabilitate' }.`
      });
      
    } catch (error) {
      console.log(error);
      this.handlePushNotificationError(error);
    }
  }

  async handleSmsNotification(e) {
    try {

      !!e.detail.checked
        ? await NotificationResource.active('sms')
        : await NotificationResource.disable('sms')

      await this.setState({
        // emailNotification: e.detail.checked
        smsNotification: await NotificationResource.status('sms')
      });

      this.props.toast.current.add({
        message: `Notifiche sms ${!!e.detail.checked ? 'attivate' : 'disabilitate' }.`
      });
      
    } catch (error) {
      console.log(error);
      this.handlePushNotificationError(error);
    }
  }

  render() {
  return (
    <>

      <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/" />
        </IonButtons>
        <IonTitle>Profilo</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>

          <IonItem>
            <IonLabel>Email</IonLabel>
            <IonToggle 
              slot="end"
              checked={ this.state.emailNotification }
              onIonChange={ this.handleEmailNotification }/>
          </IonItem>
          <IonItem>
            <IonLabel>Sms</IonLabel>
            <IonToggle 
              slot="end"
              checked={ this.state.smsNotification }
              onIonChange={ this.handleSmsNotification }/>
          </IonItem>
          <IonItem>
            <IonLabel>Notifiche push</IonLabel>
            <IonToggle 
              slot="end"
              checked={ this.state.pushNotification }
              disabled={ this.state.pushNotificationDisabled }
              onIonChange={ this.handlePushNotificationCheck }/>
          </IonItem>

        </IonList>
      </IonContent>

      <IonAlert
        isOpen={ this.state.pushNotificationAlert }
        onDidDismiss={ () => this.setState({ pushNotificationAlert: false }) }
        header={'Notifiche push'}
        message={'Vuoi attivare le notifiche push?'}
        buttons={[
          {
            text: 'Annulla',
            handler: () => this.setState({ pushNotificationAlert: false })
          },
          {
            text: 'Attiva',
            handler: () => this.handlePushNotification(true)
          }
        ]}
        />

    </>
  );
  }
}

export default withIonLifeCycle(SettingsPage);