import React from 'react';

import { IonImg, IonCol } from '@ionic/react';
const css =  { width: '100%', maxWidth: '500px' };

const HomeButton = ({action, img}) => {

    return (
        <IonCol size="6" className="btn">
            <IonImg className="homeBtn"
            onClick={action} 
            style={css}
            src={img}
            />
        </IonCol>
    );

}

export default HomeButton